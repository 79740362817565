.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row:not(:last-child) {
  margin-bottom: 16px;
}

.value {
  font-size: 17vmin;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.status {
  font-size: 5.5vmin;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.title {
  font-size: 5vmin;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  /* font-family: 'Courier New', Courier, monospace; */
}

.rc-highlight {
  background-color: #f8ffb4;
  transition: all 0.5s ease-in-out;
}

.button {
  appearance: none;
  background: none;
  font-size: 5vmin;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid ;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.textbox {
  font-size: 32px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 8px;
}

.button:hover, .button:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.button:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.asyncButton {
  composes: button;
  position: relative;
  margin-left: 8px;
}

.asyncButton:after {
  content: "";
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.asyncButton:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s
}




.backgroundAnimation {
  background-color: #47acf0;
  animation: fadeout 2s forwards;
  animation-delay: 2s;
  -moz-animation: fadeout 2s forwards;
  -moz-animation-delay: 2s;
  -webkit-animation: fadeout 2s forwards;
  -webkit-animation-delay: 2s;
  -o-animation: fadeout 2s forwards;
  -o-animation-delay: 2s;
}


@keyframes fadeout {
  to {
      background-color:#ffffff;
  }

}
@-moz-keyframes fadeout {
  to {
      background-color: #ffffff;
  }
}
@-webkit-keyframes fadeout {
  to {
      background-color: #ffffff;
  }
}
@-o-keyframes fadeout {
  to {
      background-color: #ffffff;
  }
}